<template>
  <div class="remove-container">
    <component :is="isBatch ? BatchAmbiguousBackground : SingleAmbiguousBackground" modelName="backGroundRemover"
      :modelFilepath="modelFilepath" :imageSize="imageSize" :warmupModel="warmupModel" :preProcess="preProcess"
      :postProcess="postProcess" :resizeSize="resizeSize" :imageList="imageList" @back="handleBack"
      :uploadSuccessImages="uploadSuccessImages"></component>
  </div>
</template>

<script setup>
import ndarray from "ndarray";
import ops from "ndarray-ops";
import SingleAmbiguousBackground from "@/components/models/ambiguous-background/single-ambiguous-background.vue";
import BatchAmbiguousBackground from "@/components/models/ambiguous-background/batch-ambiguous-background.vue";
import { runModelUtils } from "@/utils/index";
import { Tensor } from "onnxruntime-web";
import { ref, defineProps, defineEmits } from "vue";
import dataUtils from "@/utils/util.js";
import UnloadHandler from "@/utils/beforeunload.js";

const props = defineProps({
  imageList: Array,
  isBatch: Boolean,
  uploadSuccessImages: Array,
});
UnloadHandler(props.isBatch);
const emits = defineEmits(["back"]);
const MODEL_FILEPATH = "/models/chunk-vendors.a0e5d6d3.js";
const modelFilepath = ref(MODEL_FILEPATH);

const imageSize = {
  width: 412,
  height: 412,
};

const warmupModel = (session) => {
  return runModelUtils.warmupModel(session, [
    1,
    3,
    imageSize.width,
    imageSize.height,
  ]);
};

// 模型预测预处理
const preProcess = async (imageData, originImage) => {
  // 模型预测建议尺寸
  const resizeSize = dataUtils.getImageScaleSize(
    originImage.width,
    originImage.height
  );
  const currentWidth = resizeSize.width;
  const currentHeight = resizeSize.height;

  // 图片原尺寸的imageData对象
  // 缩放imageData对象
  const resizeData = await dataUtils.resizeImageData(
    imageData,
    currentWidth,
    currentHeight
  );
  const { data } = resizeData;
  // data processing
  const dataTensor = ndarray(new Float32Array(data), [
    currentHeight,
    currentWidth,
    4,
  ]);

  const dataProcessedTensor = ndarray(
    new Float32Array(currentWidth * currentHeight * 3),
    [1, 3, currentHeight, currentWidth]
  );

  //  获取R数据
  ops.assign(
    dataProcessedTensor.pick(0, 0, null, null),
    dataTensor.pick(null, null, 0)
  );

  //  获取G数据
  ops.assign(
    dataProcessedTensor.pick(0, 1, null, null),
    dataTensor.pick(null, null, 1)
  );

  //获取B数据
  ops.assign(
    dataProcessedTensor.pick(0, 2, null, null),
    dataTensor.pick(null, null, 2)
  );

  ops.subseq(dataProcessedTensor.pick(0, 0, null, null), 127.5);
  ops.subseq(dataProcessedTensor.pick(0, 1, null, null), 127.5);
  ops.subseq(dataProcessedTensor.pick(0, 2, null, null), 127.5);
  ops.divseq(dataProcessedTensor, 127.5);
  //转化成float32格式
  const tensor = new Tensor(
    "float32",
    new Float32Array(currentWidth * currentHeight * 3),
    [1, 3, currentHeight, currentWidth]
  );

  tensor.data.set(dataProcessedTensor.data);
  return {
    tensor,
    resizeSize,
    resizeData: resizeData,
    imageData,
  };
};
const postProcess = async (tensor, originImage, resizeData, resizeSize) => {
  try {
    for (let i = 0; i < resizeSize.width * resizeSize.height; i++) {
      let pix_now = 255 * tensor.data[i];
      resizeData.data[i * 4 + 3] = Math.round(pix_now);
    }

    const anamorphosePic = await dataUtils.resizeImageData(
      resizeData,
      originImage.width,
      originImage.height
    );
    for (let i = 0; i < anamorphosePic.width * anamorphosePic.height; i++) {
      originImage.data[i * 4 + 3] = Math.round(anamorphosePic.data[i * 4 + 3]);
    }

    return originImage;
  } catch (e) {
    alert("Model is not valid!");
  }
};

const handleBack = () => {
  emits("back");
};
</script>
<style lang="less">
.remove-container {
  width: 100%;
  min-height: calc(100vh - 60px);
}
</style>
