<template>
  <!-- 背景模糊 -->
  <div class="back-ground-remover-wrap">
    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
      <div class="btn-container" v-if="innerImageList && innerImageList.length">
        <div class="flex select-box" @click="handleSelectAll" v-if="isBatchAddBackground || isBatchDownload">
          <img :src="isSelectImageAll ? iconSelected : iconNoSelect" :style="{ width: '16px', height: '16px' }" /> <span
            :style="{ 'margin-left': '6px' }">Select
            all</span>
        </div>
        <div v-else class="flex">
          <div style="width: 270px; margin-right: 60px;">
            <!-- <div class="batch-slider-box">
              <div class="slider-text">
                Blur
              </div>
              <div class="slider-input">
                <a-slider v-model:value="blurValue" @change="e => handleBatchGaussBlur(e, item)"
                  :disabled="isHandleNum < innerImageList.length" />
              </div>
            </div> -->
          </div>
          <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
            :disabledStyle="{ background: '#d5c6ff', color: '#fff' }" :disabled="isHandleNum < innerImageList.length"
            :customStyle="{ width: '180px', height: '42px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF' }"
            @click="handleSelectBatchDownload">Batch download
          </custom-btn>
        </div>
      </div>
    </div>
    <div class="image-show-container">
      <!-- 多图 -->
      <div class="flex">
        <div class="backGroundRemovers-container" :style="gridStyle" v-if="innerImageList && innerImageList.length">
          <div class="position-box" @mouseenter="(e) => handleMattingItemBoxMouseEnter(e, item, index)"
            @mouseleave="(e) => handleMattingItemBoxMouseLeave(e, item, index)" v-for="(item, index) in innerImageList"
            :key="index">
            <div>
              <div class="result-item-box">
                <div class="image-box result-image-box" style="width: 326px; height: 326px;">
                  <image-board ref="resultBoardRef" :srcData="item.removeBgResult" :isModelDone="isModelDone"
                    :bgData="item.originImageData" :currentBlurValue="item.blurValue" v-if="item.removeBgResult"
                    :originItem="item" />
                  <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone" v-else />
                </div>
                <div class="loading-mask-box" v-if="!item.removeBgResult"></div>
                <div class="buttons-container buttons-container-center"
                  v-if="currentEditItem?.id === item.id && item.showBtns === true">
                  <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)"
                    :customStyle="{ width: '162px', height: '36px', background: '#FFFFFF', 'border-radius': '100px', color: '#060606' }"
                    @click="(e) => handleItemEdit(e, item)">Edit
                  </custom-btn>
                </div>
              </div>
              <div class="mask-box" v-if="isBatchDownload"
                :style="{ background: selectedItemIndexList.includes(item.id) ? 'rgba(0, 0, 0, 0.15)' : none }"
                @click="handleSelectItem(item)">
                <img :src="selectedItemIndexList.includes(item.id) ? iconChecked : iconNoCheck" class="icon-checkbox" />
              </div>
            </div>
          </div>
        </div>

        <loading-mask v-else />
      </div>
    </div>

    <!-- 擦除编辑弹窗 -->
    <!-- <matting-blurt-board-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" :blurValue="currentBlurValue" /> -->

    <matting-blurt-board-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" :blurValue="blurValue"
      @updateBlurValue="handleUpdateBlurValue" :isBatch="true" />

    <div class="btn-position" v-if="isBatchDownload">
      <div class="flex justify-center">
        <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)"
          :customStyle="{ width: '138px', height: '42px', background: '#fff', 'border-radius': '100px', color: '#060606', 'margin-right': '48px', 'box-shadow': '0px 6px 18px rgba(0, 0, 0, 0.1)' }"
          @click="handleCancel">Cancel
        </custom-btn>
        <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
          :customStyle="{ width: '138px', height: '42px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF', 'box-shadow': '0px 6px 18px rgba(138, 98, 255, 0.43)' }"
          @click="handleBatchDownload">Download ({{ selectedItemIndexList.length }})
        </custom-btn>
      </div>
    </div>

  </div>
</template>

<script setup>
import loadImage from "blueimp-load-image";
import BackBtn from '@/components/boolv-ui/back-btn'
import { runModelUtils } from "@/utils";
import { onMounted, ref, watch, defineProps, watchEffect, reactive, defineEmits, computed, onUnmounted } from "vue";
import MattingBlurtBoardModal from "@/components/matting-blur-board-modal";
import dataUtils from "@/utils/util.js";
import ImageBoard from '@/components/image-board';
import { isString } from 'lodash';
import dncData from '@/utils/handleDncData.js'
import { eventTracking, postCanvasToUrl } from "@/utils/eventTracking.js";
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";

import iconChecked from '@/assets/common-icons/icon_checked.svg'
import iconNoCheck from '@/assets/common-icons/icon_checkbox.svg'
import iconNoSelect from '@/assets/common-icons/icon_no_select.svg'
import iconSelected from '@/assets/common-icons/icon_selected.svg'
import LoadingMask from '@/components/boolv-ui/loading-mask/index.vue'
import { saveImgZip, transformBase64 } from '@/utils/downloadZip.js'
import {
  MessageLoading,
  destroy,
  MessageSuccess
} from "@/components/boolv-ui/Message/index.js";
import { useSideStore } from "@/store";
const sideStore = useSideStore();
const emits = defineEmits(['back']);
const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  imageUrls: Array,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
  imageList: Array
});

const innerImageList = ref([])
const inferenceTime = ref(null);
const session = ref(null);
const gpuSession = ref(null);
const cpuSession = ref(null);
const modelLoading = ref(true);
const modelInitializing = ref(true);
const sessionRunning = ref(false);
const imageURLInput = ref("");
const imageHandleComplete = ref(false);
const imageLoadingError = ref(false);
const modelLoadingError = ref(false);
const sessionBackend = ref("webgl");
const modelFile = ref(new ArrayBuffer(0));
const currentFileList = ref([]);
const bgFileList = ref([]);
const showAddDesignResult = ref(false);

const rawImage = ref(null);
const resultImage = ref(null);
const blurValue = ref(0)

const bgImage = ref(null);
const resultBoardRef = ref(null);
const isShowMattingModal = ref(false);
const isShowReplaceBgModal = ref(false);
const isShowBitchReplaceBgModal = ref(false)
const clientWidth = ref(0);
const currentBgItem = ref(null);
const isModelDone = ref(false)
const uploadError = ref(false);
const isUploadBg = ref(false)
const isBatchAddBackground = ref(false)
const isBatchDownload = ref(false)
const isSelectAll = ref(false)
const selectedItemIndexList = ref([])
const isHandleNum = ref(0)
const currentEditItem = ref(null)

const gridStyle = reactive({
  'grid-template-columns': 'repeat(4, 326px)',
  'grid-template-rows': ' 326px 326px',
  'grid-gap': '32px 25px'
});

const isSelectImageAll = computed(() => {
  return innerImageList.value.length === selectedItemIndexList.value.length
})

// 模型初始化
const initSession = async () => {
  sessionRunning.value = false;
  modelLoadingError.value = false;
  if (sessionBackend.value === "webgl") {
    if (gpuSession.value) {
      session.value = gpuSession.value;
      return;
    }
  }
  if (sessionBackend.value === "wasm") {
    if (cpuSession.value) {
      session.value = cpuSession.value;
      return;
    }
  }
  try {
    if (sessionBackend.value === "webgl") {
      gpuSession.value = await runModelUtils.createModelGpu(modelFile.value);
      session.value = gpuSession.value;
    } else if (sessionBackend.value === "wasm") {
      cpuSession.value = await runModelUtils.createModelCpu(modelFile.value);
      session.value = cpuSession.value;
    }
  } catch (e) {
    modelLoading.value = false;
    modelInitializing.value = false;
    if (sessionBackend.value === "webgl") {
      gpuSession.value = undefined;
    } else {
      cpuSession.value = undefined;
    }
    throw new Error("Error: Backend not supported. ");
  }

  modelLoading.value = false;
  if (sessionBackend.value === "webgl") {
    setTimeout(() => {
      props.warmupModel(session.value);
      modelInitializing.value = false;
    }, 0);
  } else {
    await props.warmupModel(session.value);
    modelInitializing.value = false;
  }
};

onMounted(async () => {

  //判断当前设备类型
  // const commonUploadDom = document.getElementById("bgUpload")
  // commonUploadDom.removeAttribute("capture")
  // if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
  //   // 当前设备是移动设备
  //   acceptFile.value = "image/*"
  // } else {
  //   acceptFile.value = ".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG"
  // }
  setGridStyle()
  // 加载模型，并初始化
  sideStore.isCollapse = true;
  MessageLoading(" Initializing the model...", 0, "", null, false, cancelRequest);
  sideStore.showGlobalMask = true;
  const response = await fetch(props.modelFilepath);
  const arrayBuffer = await response.arrayBuffer()
  modelFile.value = await dncData(arrayBuffer)
  try {
    await initSession();
    MessageLoading("Processing, it will take a while", 0, "", null, false, cancelRequest);
  } catch (e) {
    sessionBackend.value = "wasm";
  }

  const uploadContainer = document.getElementById('upload-container-draggle')
  uploadContainer.addEventListener('dragover', (e) => {
    e.preventDefault()
    isUploadBg.value = true
  })

  uploadContainer.addEventListener('drop', (e) => {
    e.preventDefault()
    const fileList = [
      {
        originFileObj: e.dataTransfer.files[0]
      }
    ]
    handleReplaceBgChange({ fileList })
  })

  uploadContainer.addEventListener('dragenter', () => {
    isUploadBg.value = true
  })

  uploadContainer.addEventListener('dragleave', () => {
    isUploadBg.value = false
  })
});

const cancelRequest = () => {
  destroy()
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, ind) => {
      ele.cancel()
      delete window.axiosPromiseArr[ind]
    })
  }
}

// 切换处理模式
const onSessionBackendChange = async (newVal) => {
  sessionBackend.value = newVal;
  clearCanvas();
  try {
    await initSession();
  } catch (e) {
    modelLoadingError.value = true;
  }
  return newVal;
};

watch(sessionBackend, (newVal) => {
  onSessionBackendChange(newVal);
});

watch(() => props.imageList, (newVal) => {
  if (newVal && newVal.length) {
    innerImageList.value = props.imageList.map(item => ({
      ...item,
      blurValue: 50 // 默认值50
    }));
  }
}, {
  immediate: true
});

const setGridStyle = () => {
  gridStyle['grid-template-columns'] = 'repeat(4, 326px)'
}

const modelRunning = {};

watchEffect(() => {
  if (session.value && innerImageList.value && innerImageList.value.length) {
    // 遍历数据一个一个处理
    innerImageList.value.forEach((item, index) => {
      if (!modelRunning[index]) {
        modelRunning[index] = true;
        loadImageRunModel(item.file, index);
      }
    });
  }
});

const loadImageRunModel = (file, index) => {
  loadImage(file, async (img) => {
    if (img.type === "error") {
      console.log('error')
    } else {
      const tempCtx = dataUtils.createCanvasCtx(img)
      tempCtx.drawImage(img, 0, 0)
      const imageData = tempCtx.getImageData(0, 0, img.width, img.height)
      await runModel(imageData, img, index);
    }
  },
    {
      cover: true,
      crop: true,
      canvas: true,
      crossOrigin: "Anonymous",
    });
}

const runModel = async (tempCtx, img, index) => {
  sessionRunning.value = true;
  const { tensor, resizeSize, resizeData, imageData } = await props.preProcess(
    tempCtx,
    img,
  );

  let outputTensor;
  [outputTensor] = await runModelUtils.runModel(
    session.value,
    tensor
  );

  // 绘制抠图之后的图片
  props.postProcess(outputTensor, imageData, resizeData, resizeSize, img).then(async res => {
    // 缩放imageData
    const resizeImagedata = await dataUtils.resizeImageData(res, img.width, img.height)
    innerImageList.value[index].removeBgResult = resizeImagedata
    isModelDone.value = true
    isHandleNum.value += 1
  });

  sessionRunning.value = false;
};

watchEffect(() => {
  if (innerImageList.value.length && isHandleNum.value === innerImageList.value.length) {
    sideStore.showGlobalMask = false;
    destroy()
    MessageSuccess("Processing completed");
    setTimeout(() => {
      destroy()
    }, 2000)
    sideStore.isCollapse = true;
  }
})

// 背景替换
const handleReplaceBgChange = (value, item) => {
  if (uploadError.value) {
    return;
  }
  currentEditItem.value = item
  bgFileList.value = value.fileList;
  const file = value.fileList[0].originFileObj;
  currentBgItem.value = file;
  showAddDesignResult.value = true;

  drawBgImage(file, file);
};

/** 获取指定链接下的位图图像 */
async function getLoadedImage (picFile) {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = (isString(picFile) ? picFile : URL.createObjectURL(picFile))
  await new Promise((resolve) => {
    img.onload = () => resolve();
  });
  const canvas = dataUtils.createCanvasCtx(img);
  canvas.drawImage(img, 0, 0);
  return canvas.getImageData(0, 0, img.width, img.height);
}

// 绘制背景图
const drawBgImage = async (file, data) => {
  if (!file) {
    return;
  }
  bgImage.value = await getLoadedImage(file);
  currentEditItem.value.bgImageData = await getLoadedImage(file);
  currentEditItem.value.bgImageItem = data
  innerImageList.value = innerImageList.value.map(item => {
    if (item.id === currentEditItem.value.id) {
      item.bgImageData = bgImage.value;
      item.bgImageItem = file
      return item
    }
  });
  console.log(innerImageList.value)
}

// 关闭擦除弹窗
const handleMattingModalClose = () => {
  isShowMattingModal.value = false;
};

const handleSavaMattingImage = (imageData) => {
  currentEditItem.value.removeBgResult = imageData;
};

const handleUpdateBlurValue = (value) => {
  currentEditItem.value.blurValue = value


}
const storeCurrentImage = async () => {
  const isSource = false;
  const container = document.getElementsByClassName('backGroundRemovers-container')[0];
  const domList = container.getElementsByTagName('canvas');

  await postCanvasToUrl(domList, isSource);
}
// 清除画布
const clearCanvas = () => {
  inferenceTime.value = 0;
  imageURLInput.value = "";
  // modelInProgress.value = '';
  imageHandleComplete.value = false;
  imageLoadingError.value = false;
  showAddDesignResult.value = false;
  currentFileList.value = [];
  resultImage.value = null;
  rawImage.value = null;
  dataUtils.clearCanvas('output-canvas')
  dataUtils.clearCanvas('add-canvas')
};

/**
 * 批量操作
 */
const handleMattingItemBoxMouseEnter = (e, item) => {
  if (isHandleNum.value < innerImageList.value.length) {
    return
  }
  if (!item.removeBgResult || isBatchAddBackground.value || isBatchDownload.value) {
    return
  }
  currentEditItem.value = item
  currentEditItem.value.showBtns = true
}

const handleMattingItemBoxMouseLeave = (e, item) => {
  currentEditItem.value.showBtns = false
}

const handleItemEdit = (e, item) => {
  currentEditItem.value = item;
  isShowMattingModal.value = true;
  rawImage.value = item.originImageData;
  resultImage.value = item.removeBgResult;
  blurValue.value = item.blurValue;
}

const handleSelectBatchDownload = () => {
  isBatchDownload.value = true
  selectedItemIndexList.value = innerImageList.value.map(item => item.id)
  isSelectAll.value = true
}

const handleCancel = () => {
  isBatchAddBackground.value = false
  isBatchDownload.value = false
  isSelectAll.value = false
  selectedItemIndexList.value = []
}

const handleBatchDownload = async () => {
  let imgUrlList = []

  if (!selectedItemIndexList.value || !selectedItemIndexList.value?.length) {
    selectedItemIndexList.value = innerImageList.value.map(item => item.id)
    isSelectAll.value = true

    selectedItemIndexList.value.forEach(index => {
      let item = innerImageList.value.find(item => item.id === index)
      if (!item) {
        return
      }
      transformBase64(item).then(res => {
        imgUrlList.push(res)
        if (imgUrlList.length === selectedItemIndexList.value.length) {
          saveImgZip(imgUrlList, 'background-blur')
        }
      })
    })

  } else if (selectedItemIndexList.value && selectedItemIndexList.value.length >= 1) {

    selectedItemIndexList.value.forEach(index => {

      let item = innerImageList.value.find(item => item.id === index)

      if (!item) {
        return
      }
      transformBase64(item).then(res => {
        imgUrlList.push(res)
        if (imgUrlList.length === selectedItemIndexList.value.length) {
          saveImgZip(imgUrlList, 'background-blur')
        }
      })
    })
  }
  await storeCurrentImage();
  eventTracking("booltool_page_download", { tool_name: "blur", is_batch: true, is_success: true })
}

const handleBatchAddBackground = () => {
  // 如果没有选择，全选全替换
  if (!selectedItemIndexList.value || !selectedItemIndexList.value?.length) {
    selectedItemIndexList.value = innerImageList.value.map(item => item.id)
    isSelectAll.value = true
    isShowBitchReplaceBgModal.value = true
  } else if (selectedItemIndexList.value && selectedItemIndexList.value.length >= 1) {
    isShowBitchReplaceBgModal.value = true
  } else {
    // isShowReplaceBgModal.value = true
  }
}

const handleSelectAll = (value) => {
  if (!isSelectImageAll.value) {
    selectedItemIndexList.value = innerImageList.value.map(item => item.id)
  } else {
    selectedItemIndexList.value = []
  }
}

const handleSelectItem = (item) => {
  const idx = selectedItemIndexList.value.findIndex(e => item.id === e)

  if (idx >= 0) {
    selectedItemIndexList.value.splice(idx, 1)
  } else {
    selectedItemIndexList.value.push(item.id)
  }
}

const handleBack = () => {
  emits('back')
}

onUnmounted(() => {
  destroy()
  sideStore.showGlobalMask = false;
})
</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  min-height: calc(100vh - 60px);
  min-width: 1400px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-show-container {
  min-height: calc(100vh - 100px);
}

.flex-left {
  // width: 560px;
  padding: 61px 34px;
  // margin-right: 60px;
}

.flex-left-title {
  color: #000000;
  padding-bottom: 15px;
  text-align: left;
}

.flex-right {
  padding: 61px 32px 37.63px 18px;
  // width: 560px;
}

.backGroundRemovers-container {
  // padding: 0 32px;
  width: 100%;
  min-height: calc(100vh - 60px);
  display: grid;
}

.container {
  margin-right: 30px;
}

.result-item-box {
  position: relative;
}

.result-container {
  width: 100%;
  height: 100%;
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease;
  border-radius: 8px;
  background: linear-gradient(180deg, transparent 62%, rgba(0, 0, 0, 0.00345888) 63.94%, rgba(0, 0, 0, 0.014204) 65.89%, rgba(0, 0, 0, 0.0326639) 67.83%, rgba(0, 0, 0, 0.0589645) 69.78%, rgba(0, 0, 0, 0.0927099) 71.72%, rgba(0, 0, 0, 0.132754) 73.67%, rgba(0, 0, 0, 0.177076) 75.61%, rgba(0, 0, 0, 0.222924) 77.56%, rgba(0, 0, 0, 0.267246) 79.5%, rgba(0, 0, 0, 0.30729) 81.44%, rgba(0, 0, 0, 0.341035) 83.39%, rgba(0, 0, 0, 0.367336) 85.33%, rgba(0, 0, 0, 0.385796) 87.28%, rgba(0, 0, 0, 0.396541) 89.22%, rgba(0, 0, 0, 0.4) 91.17%);
}

.add-container {
  margin-right: 0;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}


.result-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}

.position-box {
  position: relative;
  z-index: 1;

  :global(.ant-tooltip-inner) {
    border-radius: 4px;
  }

  .icon-check-original-box {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }

  .loading-mask-box {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
  }

  .mask-box {
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;

  }


  .icon-checkbox {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    font-family: 'Inter', Arial;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  .buttons-container-justify {
    display: flex;
    justify-content: center;
    padding: 0 80px 14px 80px;
  }

  .buttons-container-padding {
    padding: 0 154px 30px 154px;
  }


  .buttons-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  width: 560px;
  height: 560px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-panel {
  padding: 40px 20px;
  margin-top: 30px;
  background-color: white;
  position: relative;
}


.inputs {
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  width: 100%;
  height: 38px;
  font-size: 14px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  justify-content: center;
  padding: 0 16px;
}

.inputs:focus,
.inputs:hover {
  position: relative;
  background: rgba(0, 0, 0, 0.12);
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 270px;
  height: 64px;
  border-radius: 60px;
}

.add-design-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 154px 100px;
}

.add-design-container-hover {
  background-color: #ECECEC;

  :global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text-color) {
    background-color: #ECECEC;
  }

}

:global(.add-design-container .ant-upload.ant-upload-drag .ant-upload) {
  width: 270px;
  height: 64px;
  border: 1px dashed #878787;
  border-radius: 60px;
  background: #f7f7f7;
  padding: 0;
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  text-align: center;
  color: #969696;
  cursor: pointer;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  height: 100%;
  font-size: 14px;
  line-height: 64px;
  color: #969696;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text:hover) {
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag) {
  border: none;
}

.add-design-tip {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 42px;

  P {
    margin-bottom: 0;
  }
}

.bg-items {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 42px;

}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

:global(.unreal-dragger.ant-upload.ant-upload-drag) {
  width: 560px;
  height: 560px;
  border: none;
  background: none;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  margin: 0;
  border-radius: 60px;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  padding: 0;
  // background-color: rgba(135, 94, 255, 0.12);
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover) {
  background-color: rgba(135, 94, 255, 0.12);
}

:global(.upload-box .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #F7F7F7;
}

.back-btn-container {
  padding-top: 25px;
}

.btn-container {
  width: 100%;
  display: flex;
  padding: 16px 0 36px 0;
  justify-content: flex-end;
  align-items: center;
}

.btn-position {
  width: 100%;
  position: fixed;
  bottom: 68px;
  z-index: 99;
}

.select-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 42px;
}

.slider-container {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  margin-top: 38px;

  .slider-box {
    width: 472px;
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 42px;

  }

  .slider-text {
    width: 42px;
    margin-right: 12px;
  }

  .slider-input {
    flex: 1;
  }
}

.batch-slider-box {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;

  .slider-text {
    width: 42px;
    margin-right: 12px;
  }

  .slider-input {
    flex: 1;
  }
}
</style>
