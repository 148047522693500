"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearCanvas = exports.generateResultImage = exports.generateResultImageURL = exports.drawBrushPoint = exports.transformedDrawImage = exports.getLoadedImage = exports.showCanvas = exports.hideCanvas = exports.createContext2D = exports.resizeCanvas = void 0;
var lodash_1 = require("lodash");
var constants_1 = require("./constants");
function resizeCanvas(config) {
    var ctx = config.ctx, targetWidth = config.targetWidth, targetHeight = config.targetHeight, hiddenCtx = config.hiddenCtx, blurCtx = config.blurCtx, transformConfig = config.transformConfig, _a = config.withBorder, withBorder = _a === void 0 ? false : _a;
    var positionRange = transformConfig.positionRange, scaleRatio = transformConfig.scaleRatio;
    ctx.canvas.width = targetWidth;
    ctx.canvas.height = targetHeight;
    ctx.imageSmoothingEnabled = constants_1.DEFAULT_IMAGE_SMOOTH_CHOICE;
    transformedDrawImage({ ctx: ctx, hiddenCtx: hiddenCtx, blurCtx: blurCtx, positionRange: positionRange, scaleRatio: scaleRatio, withBorder: withBorder, clearOld: false });
}
exports.resizeCanvas = resizeCanvas;
function createContext2D(createConfig) {
    if (createConfig === void 0) { createConfig = {}; }
    var targetSize = createConfig.targetSize, cloneCanvas = createConfig.cloneCanvas;
    var canvas = document.createElement('canvas');
    var context2D = canvas.getContext('2d');
    if (targetSize) {
        canvas.width = targetSize.width;
        canvas.height = targetSize.height;
    }
    if (cloneCanvas) {
        copyImageInCanvas(context2D, cloneCanvas);
    }
    return context2D;
}
exports.createContext2D = createContext2D;
function copyImageInCanvas(hiddenContext, cloneCanvas) {
    hiddenContext.canvas.width = cloneCanvas.width;
    hiddenContext.canvas.height = cloneCanvas.height;
    hiddenContext.drawImage(cloneCanvas, 0, 0);
}
function hideCanvas() {
    var ctxs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        ctxs[_i] = arguments[_i];
    }
    for (var _a = 0, ctxs_1 = ctxs; _a < ctxs_1.length; _a++) {
        var ctx = ctxs_1[_a];
        ctx.canvas.style.display = 'none';
    }
}
exports.hideCanvas = hideCanvas;
function showCanvas() {
    var ctxs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        ctxs[_i] = arguments[_i];
    }
    for (var _a = 0, ctxs_2 = ctxs; _a < ctxs_2.length; _a++) {
        var ctx = ctxs_2[_a];
        ctx.canvas.style.display = 'initial';
    }
}
exports.showCanvas = showCanvas;
function getLoadedImage(picFile) {
    return __awaiter(this, void 0, Promise, function () {
        var img;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    img = new Image();
                    img.crossOrigin = 'anonymous';
                    img.src = (lodash_1.isString(picFile) ? picFile : URL.createObjectURL(picFile));
                    return [4, new Promise(function (resolve) {
                            img.onload = function () { return resolve(); };
                        })];
                case 1:
                    _a.sent();
                    return [2, createImageBitmap(img)];
            }
        });
    });
}
exports.getLoadedImage = getLoadedImage;
function transformedDrawImage(transformedConfig) {
    var ctx = transformedConfig.ctx, positionRange = transformedConfig.positionRange, scaleRatio = transformedConfig.scaleRatio, hiddenCtx = transformedConfig.hiddenCtx, blurCtx = transformedConfig.blurCtx;
    var _a = transformedConfig.clearOld, clearOld = _a === void 0 ? true : _a, withBorder = transformedConfig.withBorder;
    var translateX = positionRange.minX, translateY = positionRange.minY;
    if (clearOld) {
        clearCanvas(ctx);
    }
    ctx.save();
    ctx.translate(translateX, translateY);
    ctx.scale(scaleRatio, scaleRatio);
    ctx.drawImage(blurCtx.canvas, 0, 0);
    ctx.drawImage(hiddenCtx.canvas, 0, 0);
    ctx.restore();
}
exports.transformedDrawImage = transformedDrawImage;
function drawBrushPoint(drawingConfig) {
    var ctx = drawingConfig.ctx, x = drawingConfig.x, y = drawingConfig.y, radius = drawingConfig.radius, hardness = drawingConfig.hardness;
    var _a = drawingConfig.innerColor, innerColor = _a === void 0 ? constants_1.REPAIR_POINT_INNER_COLOR : _a, _b = drawingConfig.outerColor, outerColor = _b === void 0 ? constants_1.REPAIR_POINT_OUTER_COLOR : _b;
    ctx.beginPath();
    var gradient = ctx.createRadialGradient(x, y, constants_1.GRADIENT_INNER_RADIUS, x, y, radius);
    gradient.addColorStop(constants_1.GRADIENT_BEGIN_OFFSET, innerColor);
    gradient.addColorStop(hardness, innerColor);
    gradient.addColorStop(constants_1.GRADIENT_END_OFFSET, outerColor);
    ctx.fillStyle = gradient;
    ctx.strokeStyle = outerColor;
    ctx.arc(x, y, radius, constants_1.ZERO_DEGREES, constants_1.ONE_TURN_DEGREES);
    ctx.stroke();
}
exports.drawBrushPoint = drawBrushPoint;
function generateResultImageURL(rawImage, resultCtx) {
    var resultImageCtx = createResultImageContext2D(rawImage, resultCtx);
    return resultImageCtx.canvas.toDataURL();
}
exports.generateResultImageURL = generateResultImageURL;
function generateResultImage(rawImage, resultCtx) {
    var resultImageCtx = createResultImageContext2D(rawImage, resultCtx);
    return resultImageCtx.getImageData(0, 0, rawImage.width, rawImage.height);
}
exports.generateResultImage = generateResultImage;
function createResultImageContext2D(imageSource, resultImageCtx) {
    var context2D = createRawImageContext2D(imageSource);
    drawResultImageInContext2D(context2D, resultImageCtx, imageSource);
    return context2D;
}
function createRawImageContext2D(imageSource) {
    var context2D = createContext2D({ targetSize: imageSource });
    context2D.drawImage(imageSource, 0, 0);
    return context2D;
}
function drawResultImageInContext2D(ctx, resultImageCtx, imageSource) {
    ctx.globalCompositeOperation = constants_1.GLOBAL_COMPOSITE_OPERATION_DESTINATION_IN;
    ctx.drawImage(resultImageCtx.canvas, 0, 0, imageSource.width, imageSource.height);
}
function clearCanvas(ctx) {
    var _a = ctx.canvas, width = _a.width, height = _a.height;
    ctx.clearRect(0, 0, width, height);
}
exports.clearCanvas = clearCanvas;
