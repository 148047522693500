"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInImageRange = exports.initDrawingListeners = void 0;
var drawing_helper_1 = require("./drawing-helper");
var util_1 = require("./util");
var constants_1 = require("./constants");
function initDrawingListeners(config) {
    var listenerManager = config.listenerManager;
    var listenerConfig = generateDrawingListenerConfig(config);
    var inputCtx = listenerConfig.inputBoardDrawingConfig.ctx, positionRange = listenerConfig.brushDrawingBaseConfig.positionRange;
    var boardRect = listenerConfig.boardRect, draggingInputBoard = listenerConfig.draggingInputBoard;
    resetPivotalOptions(listenerConfig);
    var drawingListener = generateDrawingListener(listenerConfig);
    var canDrawAndBindListener = false;
    listenerManager.initMouseListeners({
        mouseTarget: inputCtx.value.canvas,
        down: function (ev) {
            canDrawAndBindListener = true;
            if (canDrawAndBindListener) {
                drawingListener(ev);
            }
            return canDrawAndBindListener;
        },
        move: function (ev) {
            if (!draggingInputBoard && canDrawAndBindListener) {
                drawingListener(ev);
            }
        },
        up: function (ev) {
            if (!draggingInputBoard && canDrawAndBindListener) {
                canDrawAndBindListener = false;
                drawingListener(ev);
            }
        },
    });
}
exports.initDrawingListeners = initDrawingListeners;
function generateDrawingListenerConfig(config) {
    var imageSources = config.imageSources, _a = config.imageSources, raw = _a.raw, matting = _a.matting, initDrawingConfig = config.initDrawingConfig, boardContexts = config.boardContexts, restConfig = __rest(config, ["imageSources", "imageSources", "initDrawingConfig", "boardContexts"]);
    var inputCtx = boardContexts.inputCtx, inputHiddenCtx = boardContexts.inputHiddenCtx, inputDrawingCtx = boardContexts.inputDrawingCtx, inputBlurCtx = boardContexts.inputBlurCtx;
    var brushDrawingBaseConfig = generateBrushBaseConfig(initDrawingConfig);
    var inputBoardDrawingConfig = {
        ctx: inputCtx,
        hiddenCtx: inputHiddenCtx,
        drawingCtx: inputDrawingCtx,
        blurCtx: inputBlurCtx,
        mattingSource: matting,
    };
    return __assign({ brushDrawingBaseConfig: brushDrawingBaseConfig, mattingSources: imageSources, inputBoardDrawingConfig: inputBoardDrawingConfig }, restConfig);
}
function resetPivotalOptions(config) {
    var inputBoardDrawingConfig = config.inputBoardDrawingConfig;
    var _a = config.mattingSources, raw = _a.raw, matting = _a.matting, isErasing = config.isErasing;
    if (isErasing) {
        inputBoardDrawingConfig.mattingSource = matting;
        inputBoardDrawingConfig.hiddenCtx.value.globalCompositeOperation = constants_1.GLOBAL_COMPOSITE_OPERATION_DESTINATION_OUT;
    }
    else {
        inputBoardDrawingConfig.mattingSource = raw;
        inputBoardDrawingConfig.hiddenCtx.value.globalCompositeOperation = constants_1.GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER;
    }
}
function generateBrushBaseConfig(config) {
    var rawRadius = config.radius, hardness = config.hardness, zoomRatio = config.zoomRatio, _a = config.transformConfig, scaleRatio = _a.scaleRatio, positionRange = _a.positionRange;
    var radius = util_1.computeRealRadius(rawRadius.value, scaleRatio) * 2;
    var stepBase = util_1.computeStepBase(radius);
    var step = util_1.computeStep(radius);
    return { radius: radius, step: step, stepBase: stepBase, scaleRatio: scaleRatio, positionRange: positionRange, hardness: hardness.value, zoomRatio: zoomRatio.value };
}
function generateDrawingListener(config) {
    var brushDrawingBaseConfig = config.brushDrawingBaseConfig, _a = config.brushDrawingBaseConfig, step = _a.step, scaleRatio = _a.scaleRatio, zoomRatio = _a.zoomRatio, positionRange = _a.positionRange;
    var inputBoardDrawingConfig = config.inputBoardDrawingConfig, isErasing = config.isErasing;
    var totalMovement = 0;
    return function (ev) {
        var positionAndMovements = computePositionAndMovements({
            ev: ev,
            scaleRatio: scaleRatio,
            zoomRatio: zoomRatio,
            positionRange: positionRange,
        });
        var movementX = positionAndMovements.movementX, movementY = positionAndMovements.movementY;
        var commonPointConfig = __assign(__assign({}, brushDrawingBaseConfig), positionAndMovements);
        totalMovement += util_1.getRawDistance(movementX, movementY);
        if (canDrawing(totalMovement, step, ev.type)) {
            totalMovement = 0;
            drawing_helper_1.executeMattingDrawing(__assign(__assign(__assign({}, commonPointConfig), inputBoardDrawingConfig), { isErasing: isErasing }));
        }
    };
}
function canDrawing(totalMovement, step, eventType) {
    return totalMovement >= step || eventType === constants_1.EventType.MouseDown;
}
function computePositionAndMovements(config) {
    var ev = config.ev, scaleRatio = config.scaleRatio, zoomRatio = config.zoomRatio, positionRange = config.positionRange;
    var minX = positionRange.minX, minY = positionRange.minY;
    var movementX = ev.movementX, movementY = ev.movementY, offsetX = ev.offsetX, offsetY = ev.offsetY;
    var realPosition = computeRealPosition({ offsetX: offsetX, offsetY: offsetY, minX: minX, minY: minY, scaleRatio: scaleRatio, zoomRatio: zoomRatio });
    var realMovements = computeRealMovements({ movementX: movementX, movementY: movementY }, scaleRatio, zoomRatio);
    return __assign(__assign({}, realPosition), realMovements);
}
function computeRealPosition(config) {
    var offsetX = config.offsetX, offsetY = config.offsetY, minX = config.minX, minY = config.minY, scaleRatio = config.scaleRatio, zoomRatio = config.zoomRatio;
    var x = (offsetX / zoomRatio - minX) / scaleRatio * 2;
    var y = (offsetY / zoomRatio - minY) / scaleRatio * 2;
    return { x: x, y: y };
}
function computeRealMovements(rawMovements, scaleRatio, zoomRatio) {
    var rawMovementX = rawMovements.movementX, rawMovementY = rawMovements.movementY;
    var movementX = rawMovementX / scaleRatio * 2;
    var movementY = rawMovementY / scaleRatio * 2;
    return { movementX: movementX, movementY: movementY };
}
function canDrawAndBindMoveListener(canDrawAndBindConfig) {
    var ev = canDrawAndBindConfig.ev, boardRect = canDrawAndBindConfig.boardRect, positionRange = canDrawAndBindConfig.positionRange, draggingInputBoard = canDrawAndBindConfig.draggingInputBoard;
    var pageX = ev.pageX, pageY = ev.pageY;
    var left = boardRect.left, top = boardRect.top;
    var minX = positionRange.minX, maxX = positionRange.maxX, minY = positionRange.minY, maxY = positionRange.maxY;
    var x = computePivot(pageX, left);
    var y = computePivot(pageY, top);
    var inImageRange = isInImageRange({ x: x, y: y, minX: minX, maxX: maxX, minY: minY, maxY: maxY });
    return inImageRange && !draggingInputBoard;
}
function isInImageRange(inRangeConfig) {
    var x = inRangeConfig.x, y = inRangeConfig.y, minX = inRangeConfig.minX, maxX = inRangeConfig.maxX, minY = inRangeConfig.minY, maxY = inRangeConfig.maxY;
    return x >= minX && x <= maxX && y >= minY && y <= maxY;
}
exports.isInImageRange = isInImageRange;
function computePivot(pagePivot, leftOrTop) {
    return pagePivot - leftOrTop;
}
