<template>
  <div>
    <!-- 背景模糊 -->
    <ambiguous-background v-if="uploadComplete" :imageList="uploadImageList" @back="handleBack" :isBatch="isBatch"
      :uploadSuccessImages="uploadSuccessImages" />
    <bg-remove-upload @uploadComplete="handleUploadComplete" @isBatch="setIsBatch"
      @setUploadSuccessImages="handleUploadSuccessImages" v-else />
  </div>
</template>
<script setup>
import bgRemoveUpload from './image-upload.vue'
import AmbiguousBackground from './ambiguous-background.vue'
import { ref } from 'vue'
import { eventTracking } from "@/utils/eventTracking.js";
import { useCheckResourceStore } from "@/store";
import { authenticator } from "@/utils";

const { deductResourceFn } = useCheckResourceStore();
const uploadComplete = ref(false)
const uploadImageList = ref([])
const isBatch = ref(false)
const uploadSuccessImages = ref([])

const handleUploadComplete = async (imageList) => {
  eventTracking("booltool_page_upload", { tool_name: "blur", is_batch: isBatch.value });
  await deductResourceFn('BACKGROUND_BLUR', false);
  await authenticator.refresh();
  uploadComplete.value = true
  uploadImageList.value = imageList
}

const handleBack = () => {
  uploadImageList.value = []
  uploadComplete.value = false
  isBatch.value = false
}

const setIsBatch = (value) => {
  isBatch.value = value
}

const handleUploadSuccessImages = (uploadImages) => {
  uploadSuccessImages.value = uploadImages
}
</script>

<style lang="less">

</style>