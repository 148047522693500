<template>
  <!-- 背景模糊 -->
  <div class="ambiguous-ground-remover-wrap">
    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
    </div>

    <div class="image-show-container" :style="{ marginTop: minClient ? '24px' : '49px' }">
      <!-- 单图 -->
      <div v-if="innerImageList && innerImageList.length === 1">
        <div class="flex justify-center" v-for="(item, index) in innerImageList" :key="index">
          <div class="flex-left" :style="{ width: currentContainerSize.width }">
            <div class="result-container container">
              <div class="position-box" @mouseenter="(e) => handleMattingItemBoxMouseEnter(e, item, index)"
                @mouseleave="() => handleMattingItemBoxMouseLeave(e, item, index)">
                <div class="result-item-box">
                  <div class="image-box result-image-box"
                    :style="{ width: currentContainerSize.width, height: currentContainerSize.height }">
                    <image-board ref="resultBoardRef" :isModelDone="isModelDone" :bgData="item.originImageData"
                      :srcData="item.removeBgResult" :currentBlurValue="item.blurValue" :imageFile="item.file"
                      v-if="item.removeBgResult" :originItem="item" :currentContainerSize="currentContainerSize.width" />
                    <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone"
                      :currentContainerSize="currentContainerSize.width" v-else />
                  </div>
                  <div class="loading-mask-box" v-if="!item.removeBgResult"></div>
                  <div class="buttons-container buttons-container-justify"
                    v-if="currentEditItem?.id === item.id && item.showBtns === true">
                    <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)"
                      :customStyle="{ width: '120px', height: '36px', background: '#fff', 'border-radius': '100px', color: '#060606', 'margin-right': '12px', 'margin-bottom': '16px' }"
                      @click="(e) => handleItemEdit(e, item)">Edit
                    </custom-btn>
                    <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
                      :customStyle="{ width: '120px', height: '36px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF', 'margin-bottom': '16px' }"
                      @click="(e) => handleMattingDownload(e, item)">Download
                    </custom-btn>
                  </div>
                </div>
              </div>

              <div class="slider-container">
                <div class="slider-box">
                  <div class="slider-text">
                    Blur
                  </div>
                  <div class="slider-input">
                    <a-slider v-model:value="item.blurValue" :disabled="!item.removeBgResult"
                      @afterChange="e => handleBlurChange(e, item)" max="100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 擦除编辑弹窗 -->
    <matting-blur-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" :blurValue="blurValue"
      @updateBlurValue="handleUpdateBlurValue" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, defineProps, watchEffect, defineEmits, onUnmounted, computed } from "vue";
import loadImage from "blueimp-load-image";
import { isString } from 'lodash';
import * as md5 from 'md5';
import BackBtn from '@/components/boolv-ui/back-btn'
import { eventTracking, postCanvasToUrl } from "@/utils/eventTracking.js";
import MattingBlurModal from "@/components/matting-blur-board-modal";
import ImageBoard from '@/components/image-board';
import { renderCoverBgImageInCanvas } from '@/components/matting-blur-board/helpers/drawing-helper';
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import dncData from '@/utils/handleDncData.js'
import dataUtils from "@/utils/util.js";
import { storeToRefs } from 'pinia'
import { runModelUtils } from "@/utils";
import {
  MessageError,
  MessageLoading,
  destroy,
  MessageSuccess
} from "@/components/boolv-ui/Message/index.js";

import { useSideStore } from '@/store/index.js'
const sideStore = useSideStore()

const { minClient } = storeToRefs(sideStore)

const emits = defineEmits(['back']);

const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  imageUrls: Array,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
  imageList: Array
});

const innerImageList = ref([])
const inferenceTime = ref(null);
const session = ref(null);
const gpuSession = ref(null);
const cpuSession = ref(null);
const modelLoading = ref(true);
const modelInitializing = ref(true);
const sessionRunning = ref(false);
const imageURLInput = ref("");
const imageHandleComplete = ref(false);
const imageLoadingError = ref(false);
const modelLoadingError = ref(false);
const sessionBackend = ref("wasm");
const modelFile = ref(new ArrayBuffer(0));
const currentFileList = ref([]);
const bgFileList = ref([]);
const showAddDesignResult = ref(false);

const rawImage = ref(null);
const resultImage = ref(null);
const blurValue = ref(0);

const bgImage = ref(null);
const resultBoardRef = ref(null);
const isShowMattingModal = ref(false);
const currentBgItem = ref(null);
const isModelDone = ref(false)
const uploadError = ref(false);
const isUploadBg = ref(false)
const isBatchAddBackground = ref(false)
const isBatchDownload = ref(false)
const isHandleNum = ref(0)
const currentEditItem = ref(null)
const initImageData = ref(false)


const currentContainerSize = computed(() => {
  if (sideStore.minClient) {
    return {
      width: '522px',
      height: '522px'
    }
  } else {
    return {
      width: '560px',
      height: '560px'
    }
  }
})

// 模型初始化
const initSession = async () => {
  sessionRunning.value = false;
  modelLoadingError.value = false;
  if (sessionBackend.value === "webgl") {
    if (gpuSession.value) {
      session.value = gpuSession.value;
      return;
    }
  }
  if (sessionBackend.value === "wasm") {
    if (cpuSession.value) {
      session.value = cpuSession.value;
      return;
    }
  }
  try {
    if (sessionBackend.value === "webgl") {
      gpuSession.value = await runModelUtils.createModelGpu(modelFile.value);
      session.value = gpuSession.value;
    } else if (sessionBackend.value === "wasm") {
      cpuSession.value = await runModelUtils.createModelCpu(modelFile.value);
      session.value = cpuSession.value;
    }
  } catch (e) {
    modelLoading.value = false;
    modelInitializing.value = false;
    if (sessionBackend.value === "webgl") {
      gpuSession.value = undefined;
    } else {
      cpuSession.value = undefined;
    }
    throw new Error("Error: Backend not supported. ");
  }

  modelLoading.value = false;
  if (sessionBackend.value === "webgl") {
    setTimeout(() => {
      props.warmupModel(session.value);
      modelInitializing.value = false;
    }, 0);
  } else {
    await props.warmupModel(session.value);
    modelInitializing.value = false;
  }
};

onMounted(async () => {
  //判断当前设备类型
  // const commonUploadDom = document.getElementById("bgUpload")
  // commonUploadDom.removeAttribute("capture")
  // if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
  //   // 当前设备是移动设备
  //   acceptFile.value = "image/*"
  // } else {
  //   acceptFile.value = ".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG"
  // }
  // 加载模型，并初始化
  sideStore.isCollapse = true;
  MessageLoading(" Initializing the model...", 0, "", null, false, cancelRequest);
  sideStore.showGlobalMask = true;
  const response = await fetch(props.modelFilepath);
  const arrayBuffer = await response.arrayBuffer()
  modelFile.value = await dncData(arrayBuffer)
  try {
    await initSession();
    MessageLoading("Processing, it will take a while", 0, "", null, false, cancelRequest);
  } catch (e) {
    sessionBackend.value = "wasm";
  }

  const uploadContainer = document.getElementById('upload-container-draggle')
  uploadContainer?.addEventListener('dragover', (e) => {
    e.preventDefault()
    isUploadBg.value = true
  })

  uploadContainer?.addEventListener('drop', (e) => {
    e.preventDefault()
    const fileList = [
      {
        originFileObj: e.dataTransfer.files[0]
      }
    ]
    handleReplaceBgChange({ fileList })
  })

  uploadContainer?.addEventListener('dragenter', () => {
    isUploadBg.value = true
  })

  uploadContainer?.addEventListener('dragleave', () => {
    isUploadBg.value = false
  })
});

const cancelRequest = () => {
  destroy()
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, ind) => {
      ele.cancel()
      delete window.axiosPromiseArr[ind]
    })
  }
}

// 切换处理模式
const onSessionBackendChange = async (newVal) => {
  sessionBackend.value = newVal;
  clearCanvas();
  try {
    await initSession();
  } catch (e) {
    modelLoadingError.value = true;
  }
  return newVal;
};

watch(sessionBackend, (newVal) => {
  onSessionBackendChange(newVal);
});

watch(() => props.imageList, (newVal) => {
  if (newVal && newVal.length) {
    innerImageList.value = props.imageList.map(item => ({
      ...item,
      blurValue: 50 // 默认值50
    }));
  }

}, {
  immediate: true
});

watchEffect(() => {
  if (session.value && innerImageList.value && innerImageList.value.length) {
    initImageData.value = true
    // 遍历数据一个一个处理
    innerImageList.value.forEach((item, index) => {
      if (!item.removeBgResult) {
        loadImage(
          item.file,
          (img) => {
            if (img.type === "error") {
              console.log('error')
            } else {
              const tempCtx = dataUtils.createCanvasCtx(img)
              tempCtx.drawImage(img, 0, 0)
              const imageData = tempCtx.getImageData(0, 0, img.width, img.height)
              runModel(imageData, img, index);

            }
          },
          {
            cover: true,
            crop: true,
            canvas: true,
            crossOrigin: "Anonymous",
          }
        );
      }
    })

  }
})

const runModel = async (tempCtx, img, index) => {
  sessionRunning.value = true;
  const { tensor, resizeSize, resizeData, imageData } = await props.preProcess(
    tempCtx,
    img,
  );

  let outputTensor;
  [outputTensor] = await runModelUtils.runModel(
    session.value,
    tensor
  );

  // 绘制抠图之后的图片
  props.postProcess(outputTensor, imageData, resizeData, resizeSize, img).then(async res => {
    // 缩放imageData
    const resizeImagedata = await dataUtils.resizeImageData(res, img.width, img.height)
    innerImageList.value[index].removeBgResult = resizeImagedata
    isModelDone.value = true
    isHandleNum.value += 1

    destroy()
    MessageSuccess("Processing completed");
    setTimeout(() => {
      destroy()
    }, 2000)
    sideStore.isCollapse = true;
    sideStore.showGlobalMask = false;
  }).catch(() => {
    destroy()
    MessageError("Error");
    setTimeout(() => {
      destroy()
    }, 2000)
  })

  sessionRunning.value = false;
};

// 背景替换
const handleReplaceBgChange = (value, item) => {
  if (uploadError.value) {
    return;
  }
  currentEditItem.value = item
  bgFileList.value = value.fileList;
  const file = value.fileList[0].originFileObj;
  currentBgItem.value = file;
  showAddDesignResult.value = true;

  drawBgImage(file, file);
};

/** 获取指定链接下的位图图像 */
async function getLoadedImage (picFile) {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = (isString(picFile) ? picFile : URL.createObjectURL(picFile))
  await new Promise((resolve) => {
    img.onload = () => resolve();
  });
  const canvas = dataUtils.createCanvasCtx(img);
  canvas.drawImage(img, 0, 0);
  return canvas.getImageData(0, 0, img.width, img.height);
}

// 绘制背景图
const drawBgImage = async (file, data) => {
  if (!file) {
    return;
  }
  bgImage.value = await getLoadedImage(file);
  currentEditItem.value.bgImageData = await getLoadedImage(file);
  currentEditItem.value.bgImageItem = data
  innerImageList.value = innerImageList.value.map(item => {
    if (item.id === currentEditItem.value.id) {
      item.bgImageData = bgImage.value;
      item.bgImageItem = file
      return item
    }
  });
  console.log('初始化第一次')
}

// 关闭擦除弹窗
const handleMattingModalClose = () => {
  isShowMattingModal.value = false;
};

const handleSavaMattingImage = (imageData) => {
  currentEditItem.value.removeBgResult = imageData;
};

const handleUpdateBlurValue = (value) => {
  updateItemBlurValue(currentEditItem.value, value);
}

// 抠图下载
const handleMattingDownload = async (e, item) => {
  const imageData = item.removeBgResult;
  const canvas = document.createElement('canvas')
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  let bgBitmap = null
  const resultBitmap = await createImageBitmap(item.removeBgResult);
  if (item.bgImageData) {
    bgBitmap = await createImageBitmap(item.bgImageData);
    renderCoverBgImageInCanvas(bgBitmap, ctx);
  }
  ctx.drawImage(resultBitmap, 0, 0);
  dataUtils.downloadFn(canvas, `background-blur-${md5(Date.now())}`);
  await storeCurrentImage();
  eventTracking("booltool_page_download", { tool_name: "blur", is_batch: false, is_success: true })

};

// 清除画布
const clearCanvas = () => {
  inferenceTime.value = 0;
  imageURLInput.value = "";
  // modelInProgress.value = '';
  imageHandleComplete.value = false;
  imageLoadingError.value = false;
  showAddDesignResult.value = false;
  currentFileList.value = [];
  resultImage.value = null;
  rawImage.value = null;
};

/**
 * 批量操作
 */
const handleMattingItemBoxMouseEnter = (e, item) => {
  if (isHandleNum.value < innerImageList.value.length) {
    return
  }
  if (!item.removeBgResult || isBatchAddBackground.value || isBatchDownload.value) {
    return
  }
  currentEditItem.value = item
  currentEditItem.value.showBtns = true
}

const handleMattingItemBoxMouseLeave = (e, item) => {
  currentEditItem.value.showBtns = false
}

const handleItemEdit = (e, item) => {
  currentEditItem.value = item;
  isShowMattingModal.value = true;
  rawImage.value = item.originImageData;
  resultImage.value = item.removeBgResult;
  blurValue.value = item.blurValue;
}

const handleBack = () => {
  emits('back')
}

const handleBlurChange = (value, item) => {
  updateItemBlurValue(item, value);

}
const storeCurrentImage = async () => {
  const isSource = false;
  const container = document.getElementsByClassName('result-container')[0];
  const domList = container.getElementsByTagName('canvas');
  console.log(domList);
  await postCanvasToUrl(domList, isSource);
}
const updateItemBlurValue = (target, value) => {
  innerImageList.value = innerImageList.value.map(item => {
    if (item.id === target.id) {
      return {
        ...item,
        blurValue: value
      }
    }
    return item;
  });


}

onUnmounted(() => {
  sideStore.showGlobalMask = false;
  destroy()
})
</script>

<style lang="less" scoped>
.ambiguous-ground-remover-wrap {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global(.ant-tooltip-content) {
    width: 68px;
    height: 36px;
  }

  :global(.ant-tooltip-inner) {
    border-radius: 4px;
    width: 68px;
    height: 36px;
    text-align: center;
    color: #fff;
  }
}

.top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-show-container {
  // min-height: calc(100vh - 140px);
  // margin-top: 40px;
}

.flex-left {
  // width: 560px;
}

.flex-left-title {
  color: #000000;
  padding-bottom: 15px;
  text-align: left;
}



.container {
  margin-right: 30px;
}

.result-item-box {
  position: relative;
}

.result-container {
  width: 100%;
  height: 100%;
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease;
  border-radius: 8px;
  background: linear-gradient(180deg, transparent 62%, rgba(0, 0, 0, 0.00345888) 63.94%, rgba(0, 0, 0, 0.014204) 65.89%, rgba(0, 0, 0, 0.0326639) 67.83%, rgba(0, 0, 0, 0.0589645) 69.78%, rgba(0, 0, 0, 0.0927099) 71.72%, rgba(0, 0, 0, 0.132754) 73.67%, rgba(0, 0, 0, 0.177076) 75.61%, rgba(0, 0, 0, 0.222924) 77.56%, rgba(0, 0, 0, 0.267246) 79.5%, rgba(0, 0, 0, 0.30729) 81.44%, rgba(0, 0, 0, 0.341035) 83.39%, rgba(0, 0, 0, 0.367336) 85.33%, rgba(0, 0, 0, 0.385796) 87.28%, rgba(0, 0, 0, 0.396541) 89.22%, rgba(0, 0, 0, 0.4) 91.17%);
}



.position-box {
  position: relative;
  z-index: 1;



  .icon-check-original-box {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }

  .loading-mask-box {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
  }

  .mask-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;

  }


  .icon-checkbox {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    font-family: 'Inter', Arial;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  .buttons-container-justify {
    display: flex;
    justify-content: center;
    padding: 0 80px 14px 80px;
  }

  .buttons-container-padding {
    padding: 0 154px 30px 154px;
  }


  .buttons-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  // width: 560px;
  // height: 560px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-panel {
  padding: 40px 20px;
  margin-top: 30px;
  background-color: white;
  position: relative;
}


.inputs {
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  width: 100%;
  height: 38px;
  font-size: 14px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  justify-content: center;
  padding: 0 16px;
}

.inputs:focus,
.inputs:hover {
  position: relative;
  background: rgba(0, 0, 0, 0.12);
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 270px;
  height: 64px;
  border-radius: 60px;
}

.add-design-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 154px 100px;
}

.add-design-container-hover {
  background-color: #ECECEC;

  :global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text-color) {
    background-color: #ECECEC;
  }

}

:global(.add-design-container .ant-upload.ant-upload-drag .ant-upload) {
  width: 270px;
  height: 64px;
  border: 1px dashed #878787;
  border-radius: 60px;
  background: #f7f7f7;
  padding: 0;
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  text-align: center;
  color: #969696;
  cursor: pointer;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  height: 100%;
  font-size: 14px;
  line-height: 64px;
  color: #969696;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text:hover) {
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag) {
  border: none;
}

.add-design-tip {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 42px;

  P {
    margin-bottom: 0;
  }
}

.bg-items {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 42px;

}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

// :global(.unreal-dragger.ant-upload.ant-upload-drag) {
//   width: 560px;
//   height: 560px;
//   border: none;
//   background: none;
// }

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  margin: 0;
  border-radius: 60px;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  padding: 0;
  // background-color: rgba(135, 94, 255, 0.12);
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover) {
  background-color: rgba(135, 94, 255, 0.12);
}

:global(.upload-box .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #F7F7F7;
}

.back-btn-container {
  padding-top: 25px;
}

.btn-container {
  width: 100%;
  display: flex;
  padding: 16px 0 36px 0;
  justify-content: flex-end;
  align-items: center;
}

.btn-position {
  width: 100%;
  position: fixed;
  bottom: 68px;
  z-index: 99;
}

.select-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 42px;
}

.slider-container {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  margin-top: 38px;

  .slider-box {
    width: 472px;
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 42px;

  }

  .slider-text {
    width: 42px;
    margin-right: 12px;
  }

  .slider-input {
    flex: 1;
  }
}

.batch-slider-box {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;

  .slider-text {
    width: 42px;
    margin-right: 12px;
  }

  .slider-input {
    flex: 1;
  }
}
</style>
